<script
  setup
>
  import { ref, defineProps, defineEmits, defineExpose } from "vue"
  import UiDialogHeader from "./UiDialogHeader.vue"
  import UiDialogFooter from "./UiDialogFooter.vue"

  defineProps({
    size: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    headerColor: {
      type: String,
      default: "primary",
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(["close-modal"])

  const dialogBody = ref(null)
  defineExpose({
    scrollBody({ top, left, behavior = "smooth" }) {
      dialogBody.value.scrollTo({ top, left, behavior })
    },
  })

  const emitClose = () => {
    emit("close-modal")
  }
</script>

<template>
  <section
    :class="{
      'ui-dialog': true,
      [`ui-dialog--${size}`]: size.length > 0,
      'ui-dialog--full-height': fullHeight,
    }"
  >
    <div class="ui-dialog__header">
      <slot name="header">
        <UiDialogHeader :color="headerColor">
          <template #title>
            {{ title }}
          </template>

          <template #controls>
            <button
              class="ui-dialog__close-btn"
              @click="() => emitClose()">
              <v-icon class="ui-dialog__close-ico">mdi-close</v-icon>
            </button>
          </template>
        </UiDialogHeader>
      </slot>
    </div>

    <div
      :class="{
        'ui-dialog__body-footer': true,
        'ui-dialog__body-footer--full-height': fullHeight,
      }"
    >
      <div
        ref="dialogBody"
        class="ui-dialog__body"
        :class="{
          'ui-dialog__body--no-footer': !$slots.footer,
        }"
      >
        <slot name="body" />
      </div>

      <div
        v-if="$slots.footer"
        class="ui-dialog__footer"
      >
        <UiDialogFooter>
          <slot name="footer" />
        </UiDialogFooter>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
$padding-dialog: 48px;
$default-dialog-width: calc(830px + $padding-dialog * 2);
$x-small-dialog-width: 330px;
$small-dialog-width: calc(490px + $padding-dialog * 2);

  .ui-dialog {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $default-dialog-width;
    overflow: hidden;
    max-height: calc(100% - $padding-dialog);
    flex-grow: 1;
    border-radius: 4px;

    &--full-height {
      justify-content: flex-start;
    }

    &--x-small {
      width: $x-small-dialog-width;
    }

    &--small {
      width: $small-dialog-width;
    }

    &__body-footer {
      display: flex;
      flex-direction: column;
      border-radius: 0 0 4px 4px;
      overflow: hidden;

      &--full-height {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }

    &__header,
    &__footer {
      position: relative;
      flex-shrink: 0;
    }

    &__body {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 20px 20px 0 20px;
      background-color: #fff;
      overflow-y: auto;
      height: 100%;

      &--no-footer {
        padding: 20px;
        border-radius: inherit;
      }
    }

    &__footer {
      border-radius: inherit;
    }

    &__close-btn {
      #{$root}__close-ico {
        color: currentColor;
      }
    }
  }
</style>
